import logo from './logo.svg';
import { useState } from 'react';
import './App.css';

function App() {
  const circles = [1, 2, 3, 4];
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch('https://vennda-server.vercel.app/tablepush', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSubmitted(true); // Set submitted to true after successful form submission
      return response.json();
    })
    .then(data => {
      // Handle success
    })
    .catch(error => {
      // Handle error
    });
  }

  return (
    <div className="App">
      <div className='Header'>
        <img id='logo' src="/VenndaLogo_full.svg"/>
      </div>
      <div className='Content'>
        <div className='heroText'>
          <h1 className='heroTitle'>
            Start <span className='coloredText'>tracking</span> competitors in <span className='coloredText2'>seconds</span>
          </h1>
          <p className='benefit'><span className='emoji'>🔍</span>    Automated discovery of competitors in your space</p>
          <p className='benefit'><span className='emoji'>🏆</span>   Competitor growth and performance tracking</p>
          <p className='benefit'><span className='emoji'>📋</span>    Product feature and differentiation tracking</p>
          
          {submitted ? ( // If form is submitted, show thank you message
          <div className='thankYouMessage'>
            <h3>Thank you for signing up!</h3>
          </div>
          ) : (
          <form onSubmit={handleSubmit} className="wrapper">
            <input type="email" placeholder="Enter your email" data-lpignore="true" value={email}
              onChange={(e) => setEmail(e.target.value)}/>
            <button type="submit">
              Join early access
            </button>
          </form>
          )}
        </div>
        <div className='animation'>
          <video  loop autoPlay playsInline muted>
            <source src="/productTeaser.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    {circles.map((index) => (
        <div className='Circle' id={`Circ${index}`} key={`Circ${index}`} />
      ))}
    </div>
  );
}

export default App;
